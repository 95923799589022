import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from 'stimulus/webpack-helpers'


const application = Application.start()
export { application }
application.debug = false


application.load(definitionsFromContext(context))
window.Stimulus   = application
